import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useGetAiTokens, useStripeSession } from "../api/graphql/aiReveals";
import { useProfileQuery } from "../api/graphql/profile";
import {
  Button,
  Icon,
  IconButton,
  ModalDialog,
  ModalTrigger,
  Text,
  cn,
} from "@archery-inc/design-system";
import { amplitude } from "../api/third-party";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { AiTokensPlan } from "../__generated__/graphql";
import {
  getPricesFromPlans,
  useProPlanPricesQuery,
} from "../api/graphql/planPrices";
import CoolText from "./CoolText";

export default function AiPaywall({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (opened: boolean) => void;
}) {
  const user = useProfileQuery();
  const isPro = !!user.data?.getProfile?.isPro;
  const [currentPaywall, setCurrentPaywall] = useState<"pro" | "tokens">(() =>
    isPro ? "tokens" : "pro",
  );
  return (
    <ModalTrigger
      isOpen={isOpen}
      onOpenChange={(opened) => {
        if (isOpen !== opened && !opened) {
          amplitude.logEvent(
            "Web:Client:AiReveals:PreparePhoto:AiPaywallPopup:Close",
          );
        }
        setOpen(opened);
      }}
    >
      {/* TODO: this is to prevent a warning, we shouldn't use the modeltrigger if the modal is controlled */}
      <Button className="hidden" />
      <ModalDialog containerClassName="relative overflow-hidden w-[900px]">
        {({ close }) => (
          <div className="flex min-h-[540px]">
            <IconButton
              icon="close"
              color="black"
              className="absolute top-4 right-4"
              onPress={close}
            />
            <div className="w-1/2 hidden md:block relative">
              <video
                muted
                autoPlay
                loop
                className="w-full absolute top-1/2 -translate-y-1/2"
                src="https://public-files-cdn.mojo.video/assets/ai/previews/landing/paywall.mp4"
              />
            </div>
            <div className="w-full md:w-1/2">
              {currentPaywall === "pro" ? (
                <ProPaywall onSwitch={() => setCurrentPaywall("tokens")} />
              ) : (
                <TokensPaywall onSwitch={() => setCurrentPaywall("pro")} />
              )}
            </div>
          </div>
        )}
      </ModalDialog>
    </ModalTrigger>
  );
}

function ProPaywall({ onSwitch }: { onSwitch: () => void }) {
  const [annual, setAnnual] = useState(true);
  const navigate = useNavigate();
  const { data: proPlanPrices, loading } = useProPlanPricesQuery();
  const proPlan = getPricesFromPlans(proPlanPrices?.getProPlanPrices ?? []);

  const submit = () => {
    amplitude.logEvent(
      "Web:Client:AiReveals:PreparePhoto:AiPaywallPopup:Upgrade",
      { plan: "mojo-pro" },
    );
    navigate(
      `/subscribe?periodicity=${annual ? "annually" : "monthly"}&referral=ai-reveals`,
      { state: { from: "/" } },
    );
  };

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Icon className="size-12" icon="spinner" size="md" />
      </div>
    );
  }

  return (
    <div className="py-14 px-12">
      <h1 className="text-4xl font-medium leading-14">
        <FormattedMessage
          id="ai_reveals_upgrade_pro_title"
          values={{
            generation_type: (
              <CoolText>
                <FormattedMessage id="ai_reveals_upgrade_pro_generation_type" />
              </CoolText>
            ),
          }}
        />
      </h1>
      <div className="flex bg-cream h-9 rounded-5 relative items-center w-58 mt-8">
        <span className="absolute top-0 -left-2 z-20 text-4xs uppercase bg-yellow rounded-1 px-2 h-[18px] flex items-center font-semibold -translate-y-2/3">
          <FormattedMessage
            id="subscription_save_price"
            values={{
              price: Math.floor(
                100 - (100 * proPlan.yearlyPrice) / (12 * proPlan.monthlyPrice),
              ),
            }}
          />
        </span>
        <motion.span
          layout
          transition={{
            type: "spring",
            stiffness: 300,
            mass: 0.2,
            damping: 20,
          }}
          className={cn(
            "h-7 w-1/2 absolute top-1 bg-white rounded-5",
            annual ? "left-1" : "right-1",
          )}
        />
        <span
          className="h-full w-1/2 flex items-center justify-center z-10 cursor-pointer"
          onClick={() => setAnnual(true)}
        >
          <FormattedMessage id="subscription_page_annual" />
        </span>
        <span
          className="h-full w-1/2 flex items-center justify-center z-10 cursor-pointer"
          onClick={() => setAnnual(false)}
        >
          <FormattedMessage id="subscription_page_monthly" />
        </span>
      </div>
      <Text className="inline-block mt-3 text-xs">
        <FormattedMessage
          id="ai_reveals_upgrade_pro_price"
          values={{
            price: (
              <span className="text-3xl font-medium mr-1">
                <FormattedNumber
                  style="currency"
                  currency={proPlan.currency}
                  value={
                    annual ? proPlan.yearlyPrice / 12 : proPlan.monthlyPrice
                  }
                />
              </span>
            ),
          }}
        />{" "}
        {annual ? (
          <FormattedMessage
            id="ai_reveals_upgrade_pro_annual_billing"
            values={{
              price: (
                <FormattedNumber
                  style="currency"
                  currency={proPlan.currency}
                  value={proPlan.yearlyPrice}
                />
              ),
            }}
          />
        ) : (
          <FormattedMessage id="ai_reveals_upgrade_pro_monthly_billing" />
        )}
      </Text>
      <ul className="mt-3 flex flex-col gap-1 text-md font-regular">
        <li>
          <Icon icon="check" className="size-3 mr-2" />
          <span>
            <FormattedMessage id="ai_reveals_upgrade_pro_feature1" />
          </span>
        </li>
        <li>
          <Icon icon="check" className="size-3 mr-2" />
          <span>
            <FormattedMessage id="ai_reveals_upgrade_pro_feature2" />
          </span>
        </li>
        <li>
          <Icon icon="check" className="size-3 mr-2" />
          <span>
            <FormattedMessage id="ai_reveals_upgrade_pro_feature3" />
          </span>
        </li>
        <li>
          <Icon icon="check" className="size-3 mr-2" />
          <span>
            <FormattedMessage id="ai_reveals_upgrade_pro_feature4" />
          </span>
        </li>
        <li>
          <Icon icon="check" className="size-3 mr-2" />
          <span>
            <FormattedMessage id="ai_reveals_upgrade_pro_feature5" />
          </span>
        </li>
      </ul>
      <div className="flex flex-col items-center mt-6">
        <Button
          rightIcon="arrow_full_right"
          className="w-full"
          onPress={submit}
        >
          <FormattedMessage id="ai_reveals_upgrade_pro_button" />
        </Button>
        <Text className="text-4xs text-grey-9 font-regular mt-3">
          <FormattedMessage id="ai_reveals_upgrade_pro_button_description" />
        </Text>
        <p
          className="cursor-pointer underline text-sm font-medium mt-4"
          onClick={onSwitch}
        >
          <FormattedMessage id="ai_reveals_upgrade_pro_switch" />
        </p>
      </div>
    </div>
  );
}

function TokensPaywall({ onSwitch }: { onSwitch: () => void }) {
  const [isLoading, setLoading] = useState(false);
  const getStripe = useStripeSession();
  const { data } = useGetAiTokens();
  const submit = async (plan: AiTokensPlan) => {
    amplitude.logEvent(
      "Web:Client:AiReveals:PreparePhoto:AiPaywallPopup:Upgrade",
      { plan: plan.name },
    );
    setLoading(true);
    await getStripe(plan.productId);
    setLoading(false);
  };
  const plan0 = data?.getAiTokensPlans[0];

  return (
    <div className="py-14 px-12">
      <h1 className="text-4xl font-medium leading-14">
        <FormattedMessage
          id="ai_reveals_tokens_title"
          values={{
            generation_type: (
              <CoolText>
                <FormattedMessage id="ai_reveals_tokens_generation_type" />
              </CoolText>
            ),
          }}
        />
      </h1>
      <ul className="flex flex-col gap-3 mt-4">
        {!data && (
          <>
            <li className="bg-[#F4F0ED]/50 w-full h-[4.5rem] rounded-3" />
            <li className="bg-[#F4F0ED]/50 w-full h-[4.5rem] rounded-3" />
            <li className="bg-[#F4F0ED]/50 w-full h-[4.5rem] rounded-3" />
          </>
        )}
        {data?.getAiTokensPlans.map(
          (plan, i) =>
            plan && (
              <li
                key={plan.productId}
                className="relative flex bg-[#F4F0ED]/50 p-4 justify-between items-center rounded-3"
              >
                {i !== 0 && (
                  <span className="absolute top-0 right-3 text-4xs uppercase bg-yellow rounded-1 px-2 h-[18px] flex items-center font-semibold -translate-y-1/3">
                    <FormattedMessage
                      id="subscription_save_price"
                      values={{
                        price: Math.floor(
                          100 *
                            ((plan0!.price * plan.tokensGiven) /
                              (plan0!.tokensGiven * plan.price) -
                              1),
                        ),
                      }}
                    />
                  </span>
                )}
                <Text>
                  <span className="text-xl font-medium">
                    <FormattedMessage
                      id="ai_reveals_tokens_amount"
                      values={{ amount: plan.tokensGiven }}
                    />
                  </span>
                  {" / "}
                  <FormattedNumber
                    style="currency"
                    value={plan.price / 100}
                    currency={plan.currency.isoCode}
                  />
                </Text>
                <Button
                  isDisabled={isLoading}
                  className="px-5"
                  onPress={() => void submit(plan)}
                >
                  <FormattedMessage id="preview.buy_tokens" />
                </Button>
              </li>
            ),
        )}
      </ul>
      <div className="border-t-grey-4 mt-5 border-t-1 flex flex-col items-center">
        <img
          src="/images/mojo_pro.svg"
          alt="mojo logo"
          className="h-6 px-6 mt-4 mb-2"
        />
        <Text className="text-md font-regular">
          <FormattedMessage id="ai_reveals_tokens_switch_pro_description" />
        </Text>
        <Button
          rightIcon="arrow_full_right"
          className="w-full mt-2"
          onPress={onSwitch}
        >
          <FormattedMessage id="ai_reveals_tokens_switch_pro_button" />
        </Button>
      </div>
    </div>
  );
}
