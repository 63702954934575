/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment GenerationFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      task {\n        type\n        status\n        parsedResult\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.GenerationFragmentFragmentDoc,
    "\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        modelData {\n          modelInfo {\n            modelName\n          }\n        }\n        presetId\n        isPro\n        isPresetNew\n        previewVideoUrl\n      }\n      nextCursor\n    }\n  }\n": types.PresetsDocument,
    "\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n": types.GetAiAnimatedElementsGenerationDocument,
    "\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        ...GenerationFragment\n      }\n      nextCursor\n    }\n  }\n": types.MyGenerationsDocument,
    "\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      ...GenerationFragment\n    }\n  }\n": types.CreateAiAnimatedElementsWithPresetsDocument,
    "\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n": types.NotifyEmailOnGenerationCompleteDocument,
    "\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n": types.SendFeedbackForGenerationDocument,
    "\n  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {\n  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {\n    removedBackgroundPictureUrl\n    bucketName\n    filePath\n    backgroundColorToUse\n  }\n}\n": types.GenerateAiAnimatedPictureInputFromBase64Document,
    "\n  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!, $originalFilePathOnBucket: String) {\n    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl, originalFilePathOnBucket: $originalFilePathOnBucket) {\n      removedBackgroundPictureUrl\n      bucketName\n      filePath\n      backgroundColorToUse\n    }\n  }\n": types.GenerateAiAnimatedPictureInputFromPictureUrlDocument,
    "\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n": types.CancelGenerationDocument,
    "\n  mutation SubscribeToWaitingList($email: String!) {\n    subscribeToWaitingList(email: $email)\n  }\n": types.SubscribeToWaitingListDocument,
    "\n  mutation CreateProjectFromGeneration($generationId: ID!) {\n    createProjectFromGeneration(generationId: $generationId) {\n      _id\n    }\n  }\n": types.CreateProjectFromGenerationDocument,
    "\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n": types.GetAiTokensPlansDocument,
    "\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n": types.GetOneOffCheckoutSessionDocument,
    "\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n": types.CreateGenerationVariationDocument,
    "\n  query GetGenerations($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationFragment\n    }\n  }\n": types.GetGenerationsDocument,
    "\n  mutation DeleteAiGeneration($generationId: ID!) {\n    deleteAiGeneration(generationId: $generationId) {\n      generationId\n    }\n  }\n": types.DeleteAiGenerationDocument,
    "\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n": types.GetUserProfilePictureUploadInfoDocument,
    "\n  query GetProPlanPrices {\n    getProPlanPrices {\n      periodicity\n      price\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n": types.GetProPlanPricesDocument,
    "\n  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {\n    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {\n      sessionId\n    }\n  }\n": types.GetCheckoutSessionDocument,
    "\n  query GetProfile {\n    getProfile {\n      uid\n      email\n      profilePictureUrl\n      name\n      isPro\n      aiCredits\n    }\n  }\n": types.GetProfileDocument,
    "\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  ": types.RemoveProfilePictureDocument,
    "\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  ": types.SetNameDocument,
    "\n  query GetSubscription {\n    getProfile {\n      uid\n      isPro\n      teams { \n        id\n        isPro\n        billingInfos {\n          store\n          validUntil\n        }\n      }\n      billingInfos {\n        store\n        validUntil\n        transactionMetadata {\n          transactionId\n          purchasedAtMs\n          productId\n          periodType\n          originalTransactionId\n          eventId\n        }\n      }\n    }\n  }\n": types.GetSubscriptionDocument,
    "\n  mutation HandleCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n": types.HandleCheckoutSessionSuccessDocument,
    "\n  query Query($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n": types.QueryDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GenerationFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      task {\n        type\n        status\n        parsedResult\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GenerationFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      task {\n        type\n        status\n        parsedResult\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        modelData {\n          modelInfo {\n            modelName\n          }\n        }\n        presetId\n        isPro\n        isPresetNew\n        previewVideoUrl\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        modelData {\n          modelInfo {\n            modelName\n          }\n        }\n        presetId\n        isPro\n        isPresetNew\n        previewVideoUrl\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        ...GenerationFragment\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        ...GenerationFragment\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n"): (typeof documents)["\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n"): (typeof documents)["\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {\n  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {\n    removedBackgroundPictureUrl\n    bucketName\n    filePath\n    backgroundColorToUse\n  }\n}\n"): (typeof documents)["\n  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {\n  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {\n    removedBackgroundPictureUrl\n    bucketName\n    filePath\n    backgroundColorToUse\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!, $originalFilePathOnBucket: String) {\n    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl, originalFilePathOnBucket: $originalFilePathOnBucket) {\n      removedBackgroundPictureUrl\n      bucketName\n      filePath\n      backgroundColorToUse\n    }\n  }\n"): (typeof documents)["\n  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!, $originalFilePathOnBucket: String) {\n    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl, originalFilePathOnBucket: $originalFilePathOnBucket) {\n      removedBackgroundPictureUrl\n      bucketName\n      filePath\n      backgroundColorToUse\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SubscribeToWaitingList($email: String!) {\n    subscribeToWaitingList(email: $email)\n  }\n"): (typeof documents)["\n  mutation SubscribeToWaitingList($email: String!) {\n    subscribeToWaitingList(email: $email)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateProjectFromGeneration($generationId: ID!) {\n    createProjectFromGeneration(generationId: $generationId) {\n      _id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProjectFromGeneration($generationId: ID!) {\n    createProjectFromGeneration(generationId: $generationId) {\n      _id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n"): (typeof documents)["\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGenerations($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  query GetGenerations($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteAiGeneration($generationId: ID!) {\n    deleteAiGeneration(generationId: $generationId) {\n      generationId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAiGeneration($generationId: ID!) {\n    deleteAiGeneration(generationId: $generationId) {\n      generationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n"): (typeof documents)["\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProPlanPrices {\n    getProPlanPrices {\n      periodicity\n      price\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProPlanPrices {\n    getProPlanPrices {\n      periodicity\n      price\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {\n    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {\n    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {\n      sessionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProfile {\n    getProfile {\n      uid\n      email\n      profilePictureUrl\n      name\n      isPro\n      aiCredits\n    }\n  }\n"): (typeof documents)["\n  query GetProfile {\n    getProfile {\n      uid\n      email\n      profilePictureUrl\n      name\n      isPro\n      aiCredits\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  "): (typeof documents)["\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubscription {\n    getProfile {\n      uid\n      isPro\n      teams { \n        id\n        isPro\n        billingInfos {\n          store\n          validUntil\n        }\n      }\n      billingInfos {\n        store\n        validUntil\n        transactionMetadata {\n          transactionId\n          purchasedAtMs\n          productId\n          periodType\n          originalTransactionId\n          eventId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSubscription {\n    getProfile {\n      uid\n      isPro\n      teams { \n        id\n        isPro\n        billingInfos {\n          store\n          validUntil\n        }\n      }\n      billingInfos {\n        store\n        validUntil\n        transactionMetadata {\n          transactionId\n          purchasedAtMs\n          productId\n          periodType\n          originalTransactionId\n          eventId\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation HandleCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n"): (typeof documents)["\n  mutation HandleCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Query($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n"): (typeof documents)["\n  query Query($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;