import { useLazyQuery, useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import {
  GetProPlanPricesQuery,
  PricePeriodicity,
} from "../../__generated__/graphql";

const GET_PRO_PLAN_PRICES = gql(`
  query GetProPlanPrices {
    getProPlanPrices {
      periodicity
      price
      currency {
        symbol
        showBefore
        isoCode
      }
    }
  }
`);

export function useProPlanPricesQuery() {
  return useQuery(GET_PRO_PLAN_PRICES);
}

const GET_CHECKOUT_SESSION = gql(`
  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {
    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {
      sessionId
    }
  }
`);

export function useCheckoutSessionQuery() {
  return useLazyQuery(GET_CHECKOUT_SESSION);
}

export function getPricesFromPlans(
  prices: GetProPlanPricesQuery["getProPlanPrices"],
) {
  const yearlyPlan = prices.find(
    (p) => p?.periodicity === PricePeriodicity.Year,
  );

  const monthlyPlan = prices.find(
    (p) => p?.periodicity === PricePeriodicity.Month,
  );

  return {
    currency: yearlyPlan?.currency?.isoCode,
    yearlyPrice: (yearlyPlan?.price ?? 0) / 100,
    monthlyPrice: (monthlyPlan?.price ?? 0) / 100,
  };
}
