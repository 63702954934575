import { create } from "zustand";
import { getFileUrl } from "../api/firebaseStorage";

interface State {
  isMuted: boolean;
  isLoggedIn: boolean;
  urlCache: Record<string, string | null>;
  isLoadingFullscreen: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setLoadingFullscreen: (isLoading: boolean) => void;
  setCacheUrl: (bucket: string, path: string) => Promise<void>;
  toggleMute: () => void;
  reset: () => void;
}

const initialState = {
  isLoggedIn: false,
  isMuted: true,
  isLoadingFullscreen: false,
  urlCache: {},
};

export const useMainStore = create<State>((set) => ({
  ...initialState,
  setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
  toggleMute: () => set((state) => ({ isMuted: !state.isMuted })),
  setLoadingFullscreen: (isLoading: boolean) =>
    set({ isLoadingFullscreen: isLoading }),
  async setCacheUrl(bucket?: string, path?: string) {
    if (bucket && path) {
      const url = await getFileUrl(bucket, path);
      set((state) => ({
        urlCache: {
          ...state.urlCache,
          [`${bucket}.${path}`]: url,
        },
      }));
    }
  },
  reset: () => set(() => initialState),
}));
