import { motion } from "framer-motion";
import { cn } from "@archery-inc/design-system";

export default function CoolText({
  className,
  colors = ["#FFD745", "#0097FF", "#FF39C6", "#0097FF", "#FFD745"],
  ...props
}: {
  children?: React.ReactNode;
  colors?: string[];
  className?: string;
}) {
  return (
    <motion.span
      className={cn(
        "bg-clip-text text-transparent whitespace-nowrap",
        className,
      )}
      initial={{ backgroundPosition: "0 0" }}
      animate={{ backgroundPositionX: ["0px", "600px"] }}
      transition={{
        duration: 6,
        ease: "linear",
        repeat: Infinity,
        repeatType: "loop",
      }}
      style={{
        backgroundImage: `linear-gradient(to right, ${colors.join(", ")})`,
        backgroundSize: "600px 100%",
      }}
      {...props}
    />
  );
}
