import {
  useMediaQuery,
  Badge,
  IconButton,
  Button,
} from "@archery-inc/design-system";
import { FormattedMessage } from "react-intl";
import { Suspense, useState } from "react";
import { amplitude } from "../api/third-party";
import { useProfileQuery } from "../api/graphql/profile";
import AiPaywall from "../common/AiPaywall";
import { Outlet } from "react-router-dom";

export default function AiRevealsLayout() {
  const { data: user } = useProfileQuery({ fetchPolicy: "cache-and-network" });
  const isMobile = useMediaQuery("md");
  const [paywallModalOpened, setPaywallModalOpened] = useState(false);

  function buyTokens() {
    amplitude.logEvent("Web:Client:AiReveals:BuyTokens");
    setPaywallModalOpened(true);
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-wrap justify-end border-b-1 fixed top-0 left-1 sm:left-66 right-0 border-grey-3 gap-2 p-1 items-center z-20 bg-white/95">
        {isMobile ? (
          <div className="flex">
            <FormattedMessage id="ai_reveal_token_available_msg" />
            <Badge color="grey" className="px-0 pl-1 ml-2">
              <span className="px-1">{user?.getProfile?.aiCredits}</span>
              <IconButton
                color="dark"
                variant="square"
                icon="plus"
                onPress={buyTokens}
              />
            </Badge>
          </div>
        ) : (
          <p>
            {user?.getProfile?.aiCredits}{" "}
            <FormattedMessage id="ai_reveal_token_available_msg" />
          </p>
        )}
        {!isMobile && (
          <Button
            color="gradient"
            variant="square"
            className="mx-3"
            onPress={buyTokens}
          >
            <FormattedMessage id="preview.buy_tokens" />
          </Button>
        )}
        <AiPaywall
          isOpen={paywallModalOpened}
          setOpen={setPaywallModalOpened}
        />
      </div>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </div>
  );
}
